import React, { useContext } from "react";
import { Link } from "gatsby";

import card from "../../images/cartao.jpg";
import HundredPercentGreenPT from "../../images/icons/GreenEnergy_PT.svg";
import HundredPercentGreenEN from "../../images/icons/GreenEnergy_EN.svg";
import Button from '@material-ui/core/Button';
import { AppContext } from "../../contexts/AppContext";
import styled from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
  padding: 100px 0 100px 0;
`

const GreenEnergyImg = styled.img`
    width: 100px;
    height: 100px;
    position: absolute;
    left: 430px;

    @media(max-width: 768px) {
        left: 60px;
        top: -135px;
    }
`

const CardImgContainer = styled.div`
    padding: 0 var(--safety-margin);
    height: auto;
`

const CardInfo = styled.div`
    display: flex;
    width: 30rem;
    padding: 0 var(--safety-margin);
    flex-direction: column;
    position: relative;
`

function CardPromo() {
    const { appState, sendEvent } = useContext(AppContext);

    let HundredPercentGreen
    switch(appState.language) {
        case "PT": 
            HundredPercentGreen = HundredPercentGreenPT
            break;
        case "EN":
            HundredPercentGreen = HundredPercentGreenEN
            break;
        default:
            HundredPercentGreen = HundredPercentGreenPT
            break;
    }
 
    return (
        <Wrapper>
            <CardImgContainer>
                <img className="card-img shadow" src={card} alt="evaz card" />
            </CardImgContainer>
            <CardInfo>
                <GreenEnergyImg src={HundredPercentGreen} />
                <span className="card-title title section_title">{appState.i18n["card_section"]["title"].toUpperCase()}</span>
                <span className="card-text secondary-text">{appState.i18n["card_section"]["text"]}</span>
                <Link to="/cartao-carregamento-carro-eletrico"><Button className="button-default shadow" variant="contained" onClick={() => sendEvent("Navigation", "Main", "Card Page")} >{appState.i18n["plans"]["moreInfo"].toUpperCase()}</Button></Link>
            </CardInfo>
        </Wrapper>
    );
}

export default CardPromo;