import React, { useContext } from "react";
import eleclerclogo from "../../images/partners/eleclerc.png";
import alfaenergialogo from "../../images/partners/alfa-energia.png";
import zmarlogo from "../../images/partners/zmar.png";
import eiLogo from "../../images/partners/exameinformatica_logo.png";
import evceLogo from "../../images/partners/evce-power.png";
import cardanLogo from "../../images/partners/cardan-logo.png";
import volteLogo from "../../images/partners/VOLT-E-LOGO.png";
import wccLogo from "../..//images/partners/wecancharge.png";
import { AppContext } from "../../contexts/AppContext";



function Partners() {
    const { appState } = useContext(AppContext);

    return (
        <div className="Partners-master-div">
            <div id="partners"></div>
            <div className="container-safety-border">
                <div className="partners-title title section_title">
                    <span>{appState.i18n["partners_section"]["title"].toUpperCase()}</span>
                </div>
                    <span className="subtitle">{appState.i18n.partners_section.subtitle}</span>
                
                <div className="Partners-cont">
                    <div className="partner-logo">
                        <a href="https://www.alfaenergia.pt/" target="_blank" rel="noopener noreferrer"><img className="partner-logo-img" alt="alfa energia logo" src={alfaenergialogo} /></a>
                    </div>
                    <div className="partner-logo">
                        <a href="https://visao.sapo.pt/volt/" target="_blank" rel="noopener noreferrer"><img className="partner-logo-img" alt="volt logo" src={eiLogo} /></a>
                    </div>
                    <div className="partner-logo">
                        <a href="https://www.zmar.eu/" target="_blank" rel="noopener noreferrer"><img className="partner-logo-img" alt="z mar eco experience logo" src={zmarlogo} /></a>
                    </div>
                    <div className="partner-logo">
                        <a href="https://www.e-leclerc.pt/" target="_blank" rel="noopener noreferrer"><img className="partner-logo-img" alt="eleclerc logo" src={eleclerclogo} /></a>
                    </div>
                    <div className="partner-logo">
                        <a href="https://evce.pt/" target="_blank" rel="noopener noreferrer"><img className="partner-logo-img" style={{maxWidth: "6rem"}}alt="EVCE Power logo" src={evceLogo} /></a>
                    </div>
                    <div className="partner-logo">
                        <a href="https://cardan.pt/contactos/cardan-penafiel/" target="_blank" rel="noopener noreferrer"><img className="partner-logo-img" alt="Cardan logo" src={cardanLogo} /></a>
                    </div>
                    <div className="partner-logo" style={{maxWidth: "6rem"}}>
                        <a href="http://wecancharge.com/"><img className="partner-logo-img" alt="We Can Charge" src={wccLogo} /></a>
                    </div>
                    <div className="partner-logo">
                        <a href="https://www.volt-e.pt/"><img className="partner-logo-img" alt="VOLT.E" src={volteLogo} /></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Partners;