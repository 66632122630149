import React, { useContext } from 'react'
import styled, { withTheme } from 'styled-components'
import MainPageFeatureContainer from '../layout/MainPageFeatureContainer'
import homecharging from '../../images/powerpacks/carregamento_casa.jpg'
import homecharging2 from '../../images/powerpacks/homecharging.jpg'
import mobility from '../../images/plans/mobility.jpg'
import residential from '../../images/plans/residential.jpg'
import volt from '../../images/plans/volt.jpg'
import { AppContext } from '../../contexts/AppContext'

const Wrapper = styled.div`
    padding: 50px 10%;
    background-color: ${props => props.theme.default.colors.veryLightGrey};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
`

const Title = styled.h2`
    font-size: 30pt;
    text-transform: uppercase;
    font-weight: 100;
`


function PublicSolutions({inverted, theme}) {
    const { appState } = useContext(AppContext);

    return (
        <Wrapper>
            <Container>
                <Title>{appState.i18n.header.publicSolutions}</Title>
                <MainPageFeatureContainer link={"/solutions/plans/mobility"} inverted={inverted} planName={"MOVE"} planExpansion={"BASE"} tileColor={theme.default.colors.plans.move} subtitle={appState.i18n.heroContainers.mobilityPageText} image={mobility} offset={"0"}/>
                <MainPageFeatureContainer link={"/solutions/plans/residential"} inverted={inverted} planName={"MOVE"} planExpansion={"HOME"} tileColor={theme.default.colors.plans.home} subtitle={appState.i18n.heroContainers.residentialPageText} image={residential} offset={"0"}/>
            </Container>
        </Wrapper>
    )
}

export default withTheme(PublicSolutions)
