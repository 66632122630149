import React, { useContext } from "react";
import { AppContext } from "../../contexts/AppContext";
import evazLogo from '../../images/logo.png'
import QuoteContainer from "../common/QuoteContainer";


function About() {
    const { appState } = useContext(AppContext);

    return (
        <div>
            <div id="about"/>
            <div className="services">
                <div className="services-section-title title section_title" >
                    <span>{appState.i18n["navs"]["about"].toUpperCase()}</span>
                </div>
                <div className="about-text secondary-text">{appState.i18n["about"]["text"]}</div>
                {/* <div style={{width: "100%", display: "flex", justifyContent: "center", margin: "50px 0"}}>
                    <img className="plans-evazlogo about-logo-img" src={evazLogo} />
                </div> */}
            </div>
            <div className="services">
                <div className="services-section-title title section_title" >
                    <span>{appState.i18n["about"]["people_about_us"].toUpperCase()}</span>
                </div>
                    <div style={{display: "flex", justifyContent: "space-around", flexWrap: "wrap"}}>
                        <QuoteContainer id={1} />
                        <QuoteContainer id={2} />
                    </div>
                <div style={{width: "100%", display: "flex", justifyContent: "center", margin: "20px 0 50px 0"}}>
                    <img alt="evaz logo" className="plans-evazlogo about-logo-img" src={evazLogo} />
                </div>
            </div>


        </div>
        
    );
}

export default About;