import { Link } from 'gatsby'
import React, { useContext } from 'react'
import styled from 'styled-components'
import { AppContext } from '../../contexts/AppContext'
import Button from '../common/Button'



const Wrapper = styled.div`
    display: flex;
    flex-direction: ${props => !props.inverted ? 'row' : 'row-reverse'};
    width: 100%;
    margin: auto;
    padding: 100px 0;
    @media(max-width:767px) {
        flex-direction: column-reverse;
    }
`

const HalfContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 5% 0 5% 0%;
`

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
`


const TitleContainer = styled.div`
    display: flex;
    flex-direction: column; 
    max-width: 600px;
    margin-left: 6%;
`

const SmallTitle = styled.h4`
    font-size: 1.1vw;
    font-weight: 300;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 5pt;
    margin-inline-end: 0;
    @media(max-width: 767px) {
        font-size: 12pt;
        margin-inline-start: 0;
    }
`

const Title = styled.div`
    /* color: #A5D65D !important; */
    font-size: clamp(24pt, 5vw, 34pt);
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: -2pt;
    margin-inline-end: 0;
    margin-bottom: 20px;
    line-height: clamp(20pt, 5vw, 34pt);
    text-transform: uppercase;
    @media(max-width: 767px) {
        line-height: 32pt;
        font-size: 24pt;
    }

`

const Subtitle = styled.h2`
    font-size: clamp(12pt, 1.4vw, 18pt);
    font-weight: 300;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;

    @media(max-width: 767px) {
        font-size: 12pt;
    }
`

const Image = styled.div`
    width: 24vw;
    height: 24vw;
    min-width: 250px;
    max-width: 350px;
    min-height: 250px;
    max-height: 350px;
    background-image: ${props => `url(${props.img})`};
    background-position: ${props => props.offset ? props.offset : '-5cm'};
    background-size: cover;
`

const PlanName = styled.h3`
    display: inline-block;
    color: #484848;
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
`

const PlanExpansion = styled.h3`
    margin-left: 3px;
    display: inline-block;
    color: ${props => props.tileColor};
    margin-block-start: 0.5em !important;
    margin-block-end: 0.5em !important;
`


export default function MainPageFeatureContainer({ planName, planExpansion, tileColor, subtitle, image, inverted, offset, link }) {

    const { appState } = useContext(AppContext);

    return (
        <Wrapper inverted={inverted}>
            <HalfContainer>
                <TitleContainer>
                    {/* <SmallTitle>Plano</SmallTitle> */}
                    {/* <Title>{title}</Title> */}
                    <Title>
                        <PlanName>{planName}</PlanName>
                        <PlanExpansion tileColor={tileColor}>{planExpansion}</PlanExpansion>
                    </Title>
                    <Subtitle>{subtitle}</Subtitle>
                    <Link to={link}>
                        <Button label={appState.i18n.plans.seeMore} margin={"15px 0 0 0"} />
                    </Link>
                </TitleContainer>
            </HalfContainer>
            <ImageContainer>
                <Image img={image} offset={offset}></Image>
            </ImageContainer>
        </Wrapper>
    )
}
