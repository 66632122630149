import React, { useContext } from 'react'
import { AppContext } from '../../contexts/AppContext';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

export default function QuoteContainer({id}) {
    const { appState } = useContext(AppContext);
    let text;

    
    switch(id) {
        case 1:
            text = appState.i18n["about"]["people_text1"];
            break;
        case 2:
            text = appState.i18n["about"]["people_text2"];
            break;
        default:
            break;
    }
    

    return (


        <div className="quote-master">
            <div className="quote-top shadow">
                <div>
                    <FormatQuoteIcon style={{fontSize: "45pt", fill: "var(--primary-color)"}}/>
                </div>
                {text}
                <div style={{display: "flex", justifyContent: "flex-end"}}>
                    <FormatQuoteIcon style={{fontSize: "45pt", fill: "var(--primary-color)"}}/>
                </div>
                <div style={{marginTop: "10px"}}>
                    {id === 1 ? <span style={{fontSize: "10pt", textDecoration: "underline"}}><a target="_blank" rel="noopener noreferrer" href="https://www.uve.pt/page/viajar-para-portugal-num-carro-eletrico/">{appState.i18n["about"]["people_subtext1"]}</a></span> : null }
                    {id === 2 ? <span style={{fontSize: "10pt"}}>{appState.i18n["about"]["people_subtext2"]}</span> : null }
                </div>
            </div>
            {/* <div className="quote-internal">
            </div> */}
        </div>
    )
}
