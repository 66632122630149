import React, { useContext } from 'react'
import styled, {withTheme} from 'styled-components'
import MainPageFeatureContainer from '../layout/MainPageFeatureContainer'
import homecharging from '../../images/powerpacks/carregamento_casa.jpg'
import homecharging2 from '../../images/powerpacks/homecharging.jpg'
import { AppContext } from '../../contexts/AppContext'

const Wrapper = styled.div`
    padding: 50px 10%;
    background-color: ${props => props.theme.default.colors.veryLightGrey};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
`

const Title = styled.h2`
    font-size: 30pt;
    text-transform: uppercase;
    font-weight: 100;
`


function PrivateSolutions({theme}) {
    const { appState } = useContext(AppContext);

    return (
        <Wrapper>
            <Container>
                <Title>{appState.i18n.header.privateSolutions}</Title>
                <MainPageFeatureContainer link={"/solutions/private/powerpack"} planName={"POWER"} planExpansion={"PACK"} tileColor={"#29ABE2"} subtitle={appState.i18n.heroContainers.powerpacksPageText} image={homecharging}/>
                {/* <MainPageFeatureContainer link={"/solutions/private/powerbox"} planName={"POWER"} planExpansion={"BOX"} tileColor={"#2e3192eb"} subtitle={appState.i18n.heroContainers.powerboxesPageText} image={homecharging2}/> */}
            </Container>
        </Wrapper>
    )
}

export default withTheme(PrivateSolutions)