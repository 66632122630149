import React, { useContext } from "react";
import { Link } from "gatsby"
import Button from '@material-ui/core/Button';
import { AppContext } from "../../contexts/AppContext";

function Banner() {
    const { appState } = useContext(AppContext);

    return (
        <div id="start" className="Banner-cont">
            <div className="banner-text-container">
                <h1 className="banner-title text-shadow">{appState.i18n["banner"]["title"]}</h1>
                <h2 className="banner-subtext text-shadow">{appState.i18n["banner"]["subtext"]}</h2>
                <Link className="banner-gotoregister-link" to="/register"><Button className="button-default shadow-lightBG" variant="contained" >{appState.i18n["get_card"].toUpperCase()}</Button></Link>
            </div>
        </div>
    );
}

export default Banner;