import React, { useContext } from 'react'
import PowerpackTwinContainer from '../layout/PowerpackTwinContainer'
import homeCharging from '../../images/powerpacks/homecharging.jpg'
import { AppContext } from '../../contexts/AppContext'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { Link } from 'gatsby'

const TwinContainer = styled.div`
        display: flex;
        flex-direction: row;
        align-items: center;

        @media (max-width: 1280px) {
            flex-direction: column;
        }
    `

const Title = styled.h2`
        font-size: var(--big-title-fontsize);
        color: var(--primary-color);
        padding-top: 2rem;
        font-weight: 600;
        text-transform: uppercase;
    `

const Subtext = styled.h4`
        max-width: 700px;

        @media (max-width: 1280px) {
            flex-direction: column;
        }
    `

const ButtonToPowerpacks = styled(Button)`
        padding: 0.6rem !important;
        width: 10rem !important;
        height: 1.2rem !important;
        line-height: 1.45 !important;
        background-color: transparent;
        box-sizing: unset !important;
        border-width: 0;
        background-color: var(--primary-color) !important;
        color: white !important;
        border-radius: 5px;
        cursor: pointer;
    `

const DetailContainer = styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 80px 0;
        min-width: 35%;
        flex: 1;
    `

const SafetyMargin = styled.div`
        width: 80%;
        max-width: 1440px;
        margin: auto;
        margin-bottom: 50px;
    `

const ImageContainer = styled.div`
        background-image: url(${homeCharging});
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right;
        width: 100%;
        min-height: 460px;
        position: static;
        display: flex;
        align-items: center;
        flex: 1;

        @media(max-width: 767px) {
            min-height: 260px;
        }
    `



export default function Powerpacks() {
    const { appState } = useContext(AppContext)

    return (
        <SafetyMargin>
            <TwinContainer>
                <DetailContainer>
                    <Title className="title">
                        Power packs
                    </Title>
                    <Subtext className="TwinContainer-text subtitle">{appState.i18n.powerpacks.calltoaction_main}</Subtext>
                    <Link to={"/powerpacks-carregamento-eletrico-casa"}><ButtonToPowerpacks>{appState.i18n.powerpacks.calltoaction_btn}</ButtonToPowerpacks></Link>
                </DetailContainer>
                <ImageContainer image={homeCharging}>
                    {/* <img className="TwinContainer-img" src={image} alt="energy image" /> */}
                </ImageContainer>
            </TwinContainer>
        </SafetyMargin>
    )
}
