import React, { useState, useContext } from "react";
import { AppContext } from "../../contexts/AppContext";


function Contacts() {

    const { appState, sendEvent } = useContext(AppContext);
    
    const defaultState = {
        name: "",
        email: "",
        subject: "",
        message: ""
    };

    const [values, setValues] = useState(defaultState);
    const [sent, setSent] = useState({
        state: false,
        message: ""
    });


    const handleInputChange = e => {
        const {name, value} = e.target;
        setValues({...values, [name]: value});
    };

    const handleMessageSend = () => {
        setSent({...sent, message: appState.i18n["contacts_section"]["sentThanks"], state: true});
        setTimeout( () => {
            setSent({...sent, message: "", state: false});
        }, 10000)
        setValues(defaultState);
    };

    const onSubmit = ev => {
        ev.preventDefault();
        submitContact()
            .then( r => {
                if(r.ok) {
                    handleMessageSend();
                } else {
                    throw new Error("Something failed")
                }
            })
            .catch(e => {
                alert("Something failed, please try again later");
            });
    };

    const submitContact = () => {
        const apiServer = `${process.env.GATSBY_API_ENDPOINT}/contacts`;
        return fetch(apiServer, {
            method: "post",
            body: JSON.stringify(values),
            headers: new Headers({
                "Content-Type": "application/json",
            }),
        })
    };

    return (
        <div className="Contacts-master-div container-safety-border">
            <div id="contacts"></div>
            <div>
                <div className="contacts-title title section_title">
                    <span>{appState.i18n["navs"]["contacts"].toUpperCase()}</span>
                </div>
                <div className="contacts-subtext">
                    <span className="subtitle">{appState.i18n["contacts_section"]["contacts_subtext"]}</span>
                </div>
            </div>
            <div className="contacts-cont">
                <form className="contacts-form" onSubmit={onSubmit}>
                    <div className="hbox">
                        <div className="name-div">
                            <label className="label" >{appState.i18n["contacts_section"]["form_name"]}</label>
                            <input required  name='name' onChange={handleInputChange} value={values.name} className="input" type="text" onClick={ () => sendEvent("Contacts", "Focus", "Name") }/>
                        </div>
                        <div className="email-div">
                            <label className="label" >{appState.i18n["contacts_section"]["form_email"]}</label>
                            <input name='email' required  onChange={handleInputChange} value={values.email} className="input" type="email" onClick={ () => sendEvent("Contacts", "Focus", "Email") }/>
                        </div>
                    </div>
                    <div className="subject-div">
                        <label className="label" >{appState.i18n["contacts_section"]["form_subject"]}</label>
                        <input name='subject' required  onChange={handleInputChange} value={values.subject} className="input subject"  type="text" onClick={ () => sendEvent("Contacts", "Focus", "Subject") } />
                    </div>
                    <div className="message-div">
                        <label className="label" >{appState.i18n["contacts_section"]["form_message"]}</label>
                        <textarea name='message' required  className="input text-area"  onChange={handleInputChange} value={values.message} type="text" maxLength="5000" onClick={ () => sendEvent("Contacts", "Focus", "Message") } />
                    </div>
                    <input className="input-button button-default shadow" type="submit" value={ sent.state ? appState.i18n["contacts_section"]["sent"] : appState.i18n["contacts_section"]["submit"]}  disabled={sent.state} onClick={ () => sendEvent("Contacts", "Click", "Send Contact") } />
                    
                    <label className="contacts-sent-thanks">{sent.message}</label>
                </form>
            </div>
        </div>
    );
}

export default Contacts;